// src/pages/LandingPage.jsx
import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Buying from '../components/Buying';
import Selling from '../components/Selling';
import Footer from '../components/Footer';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Navbar />
      <Hero />
      <Buying />
      <Selling />
      <Footer />
    </div>
  );
};

export default LandingPage;