// src/components/Hero.jsx
import React from 'react';
import boxedEth from '../assets/boxed-eth.png';

const Hero = () => {
    return (
        <section className="hero flex flex-col-reverse md:flex-row items-center justify-center px-6 py-36 min-h-screen">
            <div className="w-full md:w-2/3 flex flex-col items-center justify-center text-center">
            {/* Image */}
                <img
                    src={boxedEth}
                    alt="Boxed Ethereum"
                    className="h-[75vh] w-auto object-contain"
                />
            </div>
            {/* Textual Content */}
            <div className="w-full md:w-2/3 flex flex-col items-center md:items-start text-center md:text-left">
                <h1 className="text-[50px] font-extrabold font-['Roboto'] text-gray-800 mb-4">
                Insure any token
                </h1>
                <button className="bg-[#2a6a47] text-white text-sm font-medium px-6 py-2.5 rounded-full transition duration-300 hover:bg-[#245038] focus:outline-none focus:ring-2 focus:ring-[#245038]">
                    Enter App (soon)
                </button>
            </div>
        </section>
    );
};

export default Hero;