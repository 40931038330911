import React from 'react';
import Coin from '../assets/goldcoin.jpeg';

const Buying = () => {
  return (
    <section className="w-full max-w-7xl mx-auto h-auto relative bg-white flex flex-col-reverse lg:flex-row items-center justify-between" id="buying">
      <div className="w-full max-w-7xl h-auto px-4 lg:px-0 relative flex flex-col lg:flex-row items-center"> {/* Added flex and items-center */}
        <div>
          <div className="mt-8 text-black text-[22px] font-extrabold font-['Roboto'] leading-tight tracking-tight">Insure your principal against long tail of risks</div>
          <div className="mt-4 text-black text-lg font-medium font-['Roboto'] leading-tight tracking-tight">Worried about smart contract hacks? Operation risks? Rugpulls?<br />With an insurance token, you can redeem the principal value<br />when your token drops below principal value, regardless of the reason. </div>
          <div className="mt-8 text-black text-[22px] font-extrabold font-['Roboto'] leading-tight tracking-tight">Many insurable assets</div>
          <div className="text-black text-lg font-medium font-['Roboto']">
            <p className="mt-2">You can buy insurance token for any assets, permissionlessly. </p>
            <ul className="mt-2 list-disc leading-tight tracking-tight pl-5">
              <li>USDC or USDT in Aave</li>
              <li>sDAI / sUSDS</li>
              <li>LSTs</li>
              <li>LRTs</li>
              <li>and many more</li>
            </ul>
          </div>
        </div>
        <img
          src={Coin}
          alt="Coin"
          className="lg:ml-16 mt-8 lg:mt-0 w-2/3 lg:w-1/3" // Removed transform classes for alignment
        />
      </div>
    </section>
  );
};

export default Buying;