import React from 'react';
import sun from '../assets/sun.svg';
import menu from '../assets/menu.svg';
import discord from '../assets/discord-logo.svg';
import telegram from '../assets/telegram-logo.svg';
import x from '../assets/x-logo.png';

const Navbar = () => {
    return (
        <nav className="fixed top-0 left-0 w-full flex flex-wrap items-center justify-between px-6 py-4 bg-white z-50">
            {/* Left Section */}
            <div className="flex items-center">
                <span className="text-black text-4xl font-medium font-['Roboto'] leading-tight tracking-tight mr-2">
                    Ray
                </span>
                <img src={sun} alt="Sun" className="h-8 w-8" />
            </div>

            {/* Right Section */}
            <div className="flex items-center">
                <a href="https://docs.ray.money">
                    <span className="text-black text-base font-extrabold font-['Roboto'] leading-tight tracking-tight mr-2  cursor-pointer">Docs</span>
                </a>
                {/* <img src={x} alt="X" className="h-8 w-8 cursor-pointer" /> */}
                <a href="https://discord.gg/PZrwetHvvg">
                <img src={discord} alt="Discord" className="h-12 w-12 cursor-pointer" />
                </a>
                <a href="https://t.me/+Hl8P337xLqUzN2Qx">
                    <img src={telegram} alt="Telegram" className="h-12 w-12 cursor-pointer" />
                </a>
                <button className="bg-[#2a6a47] text-white text-sm font-medium px-6 py-2.5 rounded-full transition duration-300 hover:bg-[#245038] focus:outline-none focus:ring-2 focus:ring-[#245038]">
                    Enter App (soon)
                </button>
                {/* <img src={menu} alt="Menu" className="h-8 w-8 cursor-pointer" /> */}
            </div>
        </nav>
    );
};

export default Navbar;