import React from 'react';
import droplets from '../assets/droplets.png';

const Selling = () => {
    return (
        <div className="w-full max-w-7xl mx-auto h-auto relative bg-white p-6 lg:p-12 flex flex-col-reverse lg:flex-row items-center justify-between"> {/* Updated for responsiveness and padding */}
         <img className="mt-8 lg:mt-0 lg:w-1/2 w-full h-auto object-cover" src={droplets} alt="Droplets" /> {/* Adjusted image styling */}
            <div className="flex flex-col lg:w-1/2"> {/* Adjusted width for larger screens */}
                <h1 className="text-black text-[22px] font-extrabold font-['Roboto'] leading-tight">Sell insurance with capital efficiency</h1> {/* Updated typography */}
                <p className="text-black text-lg font-medium font-['Roboto'] leading-relaxed">Eigenlayer restakers can underwrite insurance of a token by opting into<br />Ray’s AVSs. Earning the insurance premium in addition to <br />Ethereum staking APY</p> {/* Updated typography and spacing */}
            </div>
        </div>
    );
};

export default Selling;